<template>
  <div class="main">
    <Navigation />
    <div class="d-flex justify-content-center mt-3">
      <h1>Account Settings</h1>
    </div>
    <div class="shadow rounded p-3 container mt-4">
      <div class="row">
        <div class="col-md-2 p-3">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a
                class="nav-link"
                v-bind:class="[View == 0 ? 'active' : '']"
                v-on:click="CHANGE_VIEW(0)"
                >Account Information</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                v-bind:class="[View == 1 ? 'active' : '']"
                v-on:click="CHANGE_VIEW(1)"
                >Change Password</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-10 d-flex justify-content-center flex-column">
          <div class="container p-5 mt-3" v-if="View == 0">
            <h4>Account Information</h4>
            <hr />
            <div class="row mt-5">
              <div class="col-md-6">
                <label for="firstName">First Name:</label>
                <input class="form-control" type="text" v-model="firstName" />
              </div>
              <div class="col-md-6">
                <label for="firstName">Last Name:</label>
                <input class="form-control" type="text" v-model="lastName" />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <label for="birthday">Birthday:</label>
                <input class="form-control" type="date" v-model="birthday" />
              </div>
              <div class="col-md-6">
                <label for="email">Email Address:</label>
                <input class="form-control" type="email" v-model="email" />
              </div>
            </div>
            <div class="row mt-4 d-flex flex-row-reverse">
              <div class="col-md-5 d-flex flex-row-reverse">
                <button class="btn btn-primary" v-on:click="UPDATE_INFO">
                  UPDATE
                </button>
              </div>
            </div>
          </div>
          <div class="container p-5 mt-3" v-else>
            <h4>Change Password</h4>
            <hr />
            <div class="row mt-5">
              <div class="col-md-6">
                <label for="firstName">Old Password:</label>
                <input
                  class="form-control"
                  type="password"
                  v-model="oldPassword"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <label for="firstName">New Password:</label>
                <input
                  class="form-control"
                  type="password"
                  v-model="newPassword"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <label for="firstName">Confirm New Password:</label>
                <input
                  class="form-control"
                  type="password"
                  v-model="confirmPassword"
                  v-on:keyup="CHECK_MATCH"
                />
                <span id="message"></span>
              </div>
            </div>
            <div class="row mt-4 d-flex flex-row-reverse">
              <div class="col-md-5 d-flex flex-row-reverse">
                <button class="btn btn-primary" v-on:click="CHANGE_PASSWORD">
                  CHANGE PASSWORD
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Navigation from "../components/Navigation.vue";
export default {
  name: "AccountSettings",
  data() {
    return {
      View: 0,
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      birthday: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  },
  components: {
    Navigation,
  },
  created() {
    var encoded = localStorage.getItem("log");
    var actual = JSON.parse(atob(encoded));
    this.$store.commit("SET_USERLOGON", actual);
    
    this.firstName = this.LOGON_USER.FirstName;
    this.lastName = this.LOGON_USER.LastName;
    this.email = this.LOGON_USER.Email;
    var d = new Date(this.LOGON_USER.Birthdate);
    var date = new Date(d.getTime() - d.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
    this.birthday = date;
  },
  methods: {
    CHANGE_VIEW(param) {
      if (param == 0) {
        this.View = 0;
      } else {
        this.View = 1;
      }
    },
    RESET() {
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
    },
    UPDATE_INFO() {
      if (
        this.firstName != "" &&
        this.lastName != "" &&
        this.birthday != "" &&
        this.email != ""
      ) {
        swal
          .fire({
            text: "Do you want to continue?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            confirmButtonColor: "#0d6efd",
            cancelButtonColor: "#dc3545",
          })
          .then((result) => {
            if (result.isConfirmed) {
              var obj = {
                Id: this.LOGON_USER.Id,
                FirstName: this.firstName,
                LastName: this.lastName,
                Email: this.email,
                Birthdate: this.birthday,
                createdBy: this.LOGON_USER.CreatedBy,
                Username: this.LOGON_USER.Username,
                Role: this.LOGON_USER.Role,
              };
              axios
                .patch("https://api.jilcwseeo.org/api/User/update-user", obj)
                .then((response) => {
                  if (response.status == 200) {
                    this.RESET();
                    this.SWEETALERT(
                      "Success",
                      "Record has been updated.",
                      "success"
                    );
                  }
                })
                .catch((error) => {
                  if(error.response)
                  {
                    this.SWEETALERT("Error", "Something went wrong.", "error");
                  }
                });
            }
          });
      } else {
        Swal.fire({
          title: "Please fill up all textfield.",
          icon: "warning",
          confirmButtonColor: "#0d6efd",
        });
      }
    },
    CHANGE_PASSWORD() {
      if (this.newPassword == this.confirmPassword) {
        document.getElementById("message").innerHTML = "";
        swal
          .fire({
            text: "Do you want to continue?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            confirmButtonColor: "#0d6efd",
            cancelButtonColor: "#dc3545",
          })
          .then((result) => {
            if (result.isConfirmed) {
              var obj = {
                Id: this.LOGON_USER.Id,
                Password: this.newPassword,
                Username: this.LOGON_USER.Username,
                OldPassword: this.oldPassword,
              };
              axios
                .patch("https://api.jilcwseeo.org/api/User/change-password", obj)
                .then((response) => {
                  this.RESET();
                  this.SWEETALERT(
                    "Success",
                    "Password has been changed.",
                    "success"
                  );
                })
                .catch((error) => {
                  if (error.response) {
                    this.SWEETALERT("Error", "Wrong old Password.", "error");
                  }
                });
            }
          });
      } else {
        document.getElementById("message").innerHTML =
          "Passwords doesn't match";
      }
    },
    SWEETALERT(param_title, param_text, param_action) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: param_action,
        title: param_title,
        text: param_text,
      });
    },
  },
  computed: {
    LOGON_USER() {
      return this.$store.state.Logon;
    },
  },
};
</script>
<style scoped>
.nav-link {
  cursor: pointer;
  color: black;
}
.active {
  color: #0275d8 !important;
}
#message {
  color: red;
}
</style>