<template>
 <div class="nav-container ">
    <nav class="navbar navbar-expand-lg navbar-light bg-primary">
    <router-link to="/MFR" class="navbar-brand  ml-4 nav-link text-8-Light" routerLinkActive="active-link">
      <img src="../assets/logoText.png" alt="logo" class="logo">
    </router-link>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mx-auto item-center-row">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Finance
        </a>
        <div id="drop" class="dropdown-menu" aria-labelledby="navbarDropdown">
          <router-link to="/MFR" class="dropdown-item text-8-Light" routerLinkActive="active-link">MFR</router-link>
          <router-link to="/TithersList" class="dropdown-item text-8-Light" routerLinkActive="active-link">Tithers List</router-link>
        </div>
      </li>  
       <li class="nav-item" v-if="isAdmin==0">
        <router-link to="/Church" class="nav-link text-8-Light" routerLinkActive="active-link">Church</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/Members" class="nav-link text-8-Light" routerLinkActive="active-link">Members</router-link>
      </li>
      <li class="nav-item" v-if="isAdmin==0">
        <router-link to="/Users" class="nav-link text-8-Light" routerLinkActive="active-link">User Management</router-link>
      </li>
      <li v-if="isAdmin!=3">
         <ul class="navbar-nav mr-4 item-center-row">
       <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Reports
        </a>
        <div id="drop" class="dropdown-menu" style="right: 0;" aria-labelledby="navbarDropdown">
            <router-link to="/finance-report" class="dropdown-item nav-link text-8-Light" routerLinkActive="active-link">Finance Report</router-link>
            <router-link to="/tithersyear" class="dropdown-item nav-link text-8-Light" routerLinkActive="active-link">Tithers List Report</router-link>
        </div>
      </li>
      
    </ul>
      </li>
    </ul>
   
    <ul class="navbar-nav mr-4 item-center-row">
       <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
              <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
              <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
            </svg>
            Settings
        </a>
        <div id="drop" class="dropdown-menu" style="right: 0;" aria-labelledby="navbarDropdown">
            <router-link to="/AccountSettings" class="dropdown-item nav-link text-8-Light" routerLinkActive="active-link">Account Settings</router-link>
           <router-link to="/" class="dropdown-item nav-link text-8-Light" routerLinkActive="active-link" v-on:click="Logout">Logout</router-link>
        </div>
      </li>
      
    </ul>
  </div>
</nav>

 </div>
</template>

<script>
export default {
  name: 'Navigation',
  data(){
    return{
      Role:"",
    }
  },
  created(){
    var encoded = localStorage.getItem('log');  
    var actual = JSON.parse(atob(encoded));
    this.Role = actual.Role;
  },
  methods:{
    Logout(){
      var obj={};
      this.$store.commit("SET_USERLOGON",obj);
      var encoded = btoa(JSON.stringify(obj))
      localStorage.setItem('log',encoded);
      this.$store.commit("SET_AUTHENTICATION",false);
      var encoded = btoa(false)
      localStorage.setItem('tok',encoded);

    }
  },
  computed:{
    isAdmin(){
      if(this.Role == "Admin"){
        return 0;
      }
      else if(this.Role == "Provincial"){
        return 1;    
      }
      else if(this.Role == "Country"){
        return 2;    
      }
      else{
        return 3;    
      }
    }
  }
}
</script>
<style scoped>
 .nav-container{
   width: 100%;
   height: 20%;
 }
 a{
   color:white !important;
 }

 .ml-4{
   margin-left: 1rem !important;
 }
 .mr-4{
   margin-right: 1rem !important;
 }
 .logo{
    height: 70px;
 }

  .dropdown-menu{
    background: rgba(var(--bs-primary-rgb),var(--bs-bg-opacity))!important;
    color: white !important;
  }
  .dropdown-menu > a{
        padding: 12px;
  }
  .dropdown-menu > a:hover{
    background: #084197;
    height: 100%;
  }
  .dropdown-menu > div > a:hover{
    background: #084197;
    height: 100%;
  }
</style>
